@import '../../../src/styles/typography.scss';
h1, h2, h3, h4, h5, h6, p, span, a, li {
  font-family: "Poppins", sans-serif !important;
  text-align: left;
  text-decoration: none;
} 

.app-footer{  
  background-color: #191919;
  h4{
    color: #fff;
    padding-bottom: 20px;
  }
  h6{
    font-size: 30px;
    color: #00b8ff;
    font-weight: 700;
  }
  p{
    font-size: 15px;
    color: #afafaf;
  }
  .copyRight {
    p{text-align: center;}    
  } 
}
.mainFooter {
  padding: 30px 0px;
}
.section2 {
  justify-content: flex-start !important;
    ul {
      padding: 0px 0px;
    }
    li {
      list-style: none;
      padding: 5px 0px;
    }
    a {
      text-decoration: none;
      color: #00b8ff;
    }
    a:hover {
      color: #efe348;
      text-decoration: none;
    }
}
.section3 {
  .inner {
    height: 250px;
    width: 100%;
  }
}
.copyRight {
  background-color: #434343;
  padding: 10px 0px;
  p{
    margin: 0px auto;
  }
}
$pp-purple: #861a54;
$pp-blue: #054f7d;
$pp-ltblue: #00a7cf;
$sp-yellow: #efe348;

.wrapper {
  align-items: center;
  background-image: linear-gradient(to right, $pp-blue,$pp-ltblue,$sp-yellow,$pp-purple,$pp-blue);
  background-size: 600%;
  background-position: 0 0;
  box-shadow: inset 0 0 5em rgba(0,0,0,.5);
  display: flex;
  font-family: Arial, sans-serif;
  font-display: swap;
  justify-content: center;
  /* Animation */
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-name: gradients;
  p {
    color: white;
    text-align: center;
    text-transform: uppercase;
  }
}

@keyframes gradients {
    0%   {background-position: 0 0;}
    25% {background-position: 50% 0;}
    50% {background-position: 90% 0;}
    60% {background-position: 60%;}
    75% {background-position: 40%;}
    100%  {background-position: 0 0;}
}