.App-header {
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
}

.App-header {
  .App-header-menu:hover {
    text-decoration: underline;
  }
  .app-header-link-active {   
    color: white;
  }
  .app-header-link-inactive {
    color: white;
  }
  .app-header-menu-container{
    color: white;
    background-color: transparent !important;
  }
  .app-header-menu-container ul {
      text-align: center;
      margin: 0px auto;
  }
  li.app-header-menu-items {
    float: left;
    list-style: none;
    margin: 0px 10px;
}
#navbar {
  padding: 0px;
  margin: 0px auto;
  button {
    border: none;
    background: #ffcf00;
    position: absolute;
    top: -42px;
    z-index: 1111;
    right: 0;
  }
}

li.app-header-menu-items a{
  padding: 10px 20px;
  color: #000;
  font-family: "Poppins", sans-serif;  
  font-weight: 500;
  text-decoration-line: none;
}
li.app-header-menu-items a.active {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: #fff;
}
li.app-header-menu-items a:hover {
  background-color: #ff416c;
  color: #fff;
  font-weight: 500;
  text-decoration-line: none;
  transition: background-color 0.65s, color 0.65s;
  animation: gradient 15s ease infinite;
}
#none {
  display: none;
}
.pageName{
  padding: 50px 0px;
  .inner1 {
    justify-content: center;
  }
  h1{ 
    font-size: 60px;
    text-transform: capitalize;
    font-weight: 700;
    color: #ff4700;
    text-align: center;
    justify-content: center;
  }
  .breadcrumb {
    background-color: transparent;
    margin: 0px auto;
    padding: 0px;
    svg {
      padding: 1px 5px 0px 0px;
      color: #343434;
    }
    .home {
      svg {
        padding: 1px 5px 0px 0px;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      li {
        list-style: none;
        display: inline-block;
        text-transform: capitalize;        
      }
      a {
        color: #343434;
        text-decoration: none;
        padding-right: 10px;        
      }
      a.app-header-link-inactive {
        color: #ff4700;
      }
      a:hover {
        color: #ff4700;
      }
    }
  }
}
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) {
  .App-header .pageName h1 {
    font-size: 30px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
 .App-header .pageName h1 {
    font-size: 30px !important;
  }
 }

@media (min-width: 992px) {

  li.app-header-menu-items a{
    font-size: 14px !important;
  }
  li.app-header-menu-items a:hover {
    font-size: 14px !important;
  }

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  li.app-header-menu-items a{
    font-size: 18px !important;
  }
  li.app-header-menu-items a:hover {
    font-size: 18px !important;
  }
  .App-header .pageName h1 {
    font-size: 60px !important;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  li.app-header-menu-items a{
    font-size: 18px !important;
  }
  li.app-header-menu-items a:hover {
    font-size: 18px !important;
  }
  .App-header .pageName h1 {
    font-size: 60px !important;
  }
 }