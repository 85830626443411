.app-school-details-container{
    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        a {
            text-decoration: none;
            color: transparent;
            margin: 0px auto;
            padding: 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
    }
    
    .app-school-details-loader-line{
      display: block;
      height: 1px;
      width: 100%;
      background-color: rgb(225, 225, 225);
      overflow: hidden;
    }
    h1{
        font-family: 'Sans-serif', cursive;
    }
}

.phone svg, .email svg, .map svg {
    width: 25px;
    height: 25px;
}

.top-bar-level-1 {
   display: inline-block;
   padding: 28px 0px;
}
.top-bar-level-3 {
    display: inline-block;
    width: 100%;
    text-align: justify;
    min-height: 50px;
}
.top-bar-level-3 li{
    padding-right: 10px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    display: inline-block;
    list-style: none;
}
.top-bar-level-3 li .rtc {
    float: left;
}
.top-bar-level-3 li .rtc h4 {
    color: #2b2b2b;
    font-size: 18px;
}
.top-bar-level-3 li span svg {
    color: #ff4839;
    float: left;
    padding: 18px 10px;
}
.top-bar-level-3 li p {
    color: #575757;
    margin: 0px auto;
    padding: 0px 0px;
}
.top-bar-level-3 li a{
    color: #fff;
    text-decoration: none;
}
.app-school-details-logo-container{
    padding: 9px 0px;
}
.app-school-details-name-container{
    margin: 0px 20px;
}
.app-school-details-logo-container{
    img{
        width: 100px;
        height: 100px;
    }
}


.app-school-details-name-container{ 
    h1{
        font-size: 30px;
        font-family: "Poppins", sans-serif;
    }
    h4{
        font-size: 1.5rem;
    }
    h1,h4{
        font-weight: 700;  
        color: rgb(61, 82, 142);
        text-align: center;      
    }
}
